import { CustomAlert } from "@/components/ui/alert/CustomAlert"
import { ModalComponent } from "@/components/ui/modal/Modal"
import { Button } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import React, { useEffect, useState } from "react"

import { useModalContext } from "./ModalContext"

interface BeforeInstallPromptEvent extends Event {
  platforms: () => Promise<void>
  prompt: () => Promise<void>
  userChoice: Promise<{ outcome: "accepted" | "dismissed" }>
}

export default function MobileCheck() {
  const modalName = "MobileCheck"
  const { openModal, isModalOpen, closeModal } = useModalContext()
  const [isMobile, setIsMobile] = useState(false)
  const [isIOS, setIsIOS] = useState(false)
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(
    null,
  )

  useEffect(() => {
    if (typeof window === "undefined") return

    const isFirstVisit = !localStorage.getItem("addToHomePromptShown")
    const mobileCheck = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)
    const iosCheck = /iPhone|iPad/i.test(navigator.userAgent)

    setIsIOS(iosCheck)

    const handleBeforeInstallPrompt = (e: Event) => {
      const event = e as BeforeInstallPromptEvent
      event.preventDefault()
      setDeferredPrompt(event)
      setIsMobile(mobileCheck)

      if (mobileCheck && isFirstVisit) {
        openModal(modalName)
      }
    }

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt)

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt)
    }
  }, [openModal])

  function handleModalClose() {
    closeModal(modalName)
  }

  async function handleInstallClick() {
    if (deferredPrompt) {
      await deferredPrompt.prompt()
      setDeferredPrompt(null)
      handleModalClose()
    }
  }

  return (
    <ModalComponent
      isOpen={isModalOpen(modalName)}
      setIsOpen={handleModalClose}
      sx={{
        width: "100%",
        maxWidth: "400px",
        minWidth: "380px",
        height: "100%",
        maxHeight: "280px",
        minHeight: "200px",
        overflow: "hidden",
      }}
    >
      <ModalComponent.Header />
      <ModalComponent.Content
        hasActionButton={false}
        hasBackButton={true}
        handleBackButton={handleModalClose}
      >
        <Grid
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <CustomAlert
            title="Criar atalho no seu dispositvo:"
            body={
              isMobile && isIOS
                ? "Para adicionar este site à sua tela inicial no iOS, clique no ícone de 'Compartilhar' (⬆️) na parte inferior do Safari e escolha 'Adicionar à Tela de Início'."
                : 'Clique em "SALVAR" para adicionar o atalho diretamente à sua tela inicial.'
            }
          />
          <Button variant="contained" color="primary" onClick={handleInstallClick}>
            SALVAR
          </Button>
        </Grid>
      </ModalComponent.Content>
    </ModalComponent>
  )
}
