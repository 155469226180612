"use client"

import { LoadingAnimation } from "@/components/ui/loading/LoadingAnimation"
import MobileCheck from "@/contexts/MobileCheck"
import { Suspense, lazy } from "react"

const Home = lazy(() => import("@/app/Home"))

export default function HomePage() {
  return (
    <Suspense fallback={<LoadingAnimation text="Carregando a página inicial..." />}>
      <Home />
      <MobileCheck />
    </Suspense>
  )
}
