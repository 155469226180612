import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

interface StatusStepperContextType {
  currentStep: number
  setCurrentStep: (value: number) => void
  nextStep: () => void
  prevStep: () => void
  resetCurrentStep: () => void
}

const initialContext: StatusStepperContextType = {
  currentStep: 0,
  setCurrentStep: () => {},
  nextStep: () => {},
  prevStep: () => {},
  resetCurrentStep: () => {},
}

const StatusStepperContext = createContext<StatusStepperContextType>(initialContext)

interface StatusStepperProviderProps {
  children: ReactNode
  initialStep?: number
}

export const StatusStepperProvider: React.FC<StatusStepperProviderProps> = ({
  children,
  initialStep = 0,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(initialStep)

  const nextStep = useCallback(() => setCurrentStep((prev) => prev + 1), [])
  const prevStep = useCallback(() => setCurrentStep((prev) => prev - 1), [])
  const resetCurrentStep = useCallback(() => setCurrentStep(0), [])

  const value = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      nextStep,
      prevStep,
      resetCurrentStep,
    }),
    [currentStep, nextStep, prevStep, resetCurrentStep],
  )

  return (
    <StatusStepperContext.Provider value={value}>
      {children}
    </StatusStepperContext.Provider>
  )
}

export const useStatusStepperContext = () => {
  const context = useContext(StatusStepperContext)
  if (!context) {
    throw new Error("useStatusStepper must be used within a StatusStepperProvider")
  }
  return context
}
